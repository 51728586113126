/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/components/global-styles/_global.scss"
import ReactDOM from "react-dom/client";

/**
 * By using replaceHydrateFunction as described, we effectively ensure that the Gatsby application uses the most optimal methods available to initialize and update the DOM, and it can solve problems like Uncaught Error: Minified React error(like we had for the 404 page).
 *  
 * replaceHydrateFunction that allows customizing the hydration process
 * 
 * https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#replaceHydrateFunction
 */
export const replaceHydrateFunction = () => {
  // Returns a new function that takes a React element and a DOM container
  return (element, container) => {
    // Creates a new root container with ReactDOM.createRoot.
    // This is crucial for React 18's concurrent mode and improved hydration process management.
    const root = ReactDOM.createRoot(container)
    // Renders the React element inside the root container.
    // This rendering method is optimized for React 18 and provides better state and updates management.
    root.render(element)
  }
}
exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-board-members-template-js": () => import("./../../../src/templates/board-members-template.js" /* webpackChunkName: "component---src-templates-board-members-template-js" */),
  "component---src-templates-contact-template-js": () => import("./../../../src/templates/contact-template.js" /* webpackChunkName: "component---src-templates-contact-template-js" */),
  "component---src-templates-default-template-js": () => import("./../../../src/templates/default-template.js" /* webpackChunkName: "component---src-templates-default-template-js" */),
  "component---src-templates-for-media-template-js": () => import("./../../../src/templates/for-media-template.js" /* webpackChunkName: "component---src-templates-for-media-template-js" */),
  "component---src-templates-governance-template-js": () => import("./../../../src/templates/governance-template.js" /* webpackChunkName: "component---src-templates-governance-template-js" */),
  "component---src-templates-grant-template-js": () => import("./../../../src/templates/grant-template.js" /* webpackChunkName: "component---src-templates-grant-template-js" */),
  "component---src-templates-history-template-js": () => import("./../../../src/templates/history-template.js" /* webpackChunkName: "component---src-templates-history-template-js" */),
  "component---src-templates-homepage-template-js": () => import("./../../../src/templates/homepage-template.js" /* webpackChunkName: "component---src-templates-homepage-template-js" */),
  "component---src-templates-jobs-template-js": () => import("./../../../src/templates/jobs-template.js" /* webpackChunkName: "component---src-templates-jobs-template-js" */),
  "component---src-templates-news-archive-template-js": () => import("./../../../src/templates/news-archive-template.js" /* webpackChunkName: "component---src-templates-news-archive-template-js" */),
  "component---src-templates-price-list-template-js": () => import("./../../../src/templates/price-list-template.js" /* webpackChunkName: "component---src-templates-price-list-template-js" */),
  "component---src-templates-privacy-policy-template-js": () => import("./../../../src/templates/privacy-policy-template.js" /* webpackChunkName: "component---src-templates-privacy-policy-template-js" */),
  "component---src-templates-report-template-js": () => import("./../../../src/templates/report-template.js" /* webpackChunkName: "component---src-templates-report-template-js" */),
  "component---src-templates-single-news-template-js": () => import("./../../../src/templates/single-news-template.js" /* webpackChunkName: "component---src-templates-single-news-template-js" */),
  "component---src-templates-social-responsibility-template-js": () => import("./../../../src/templates/social-responsibility-template.js" /* webpackChunkName: "component---src-templates-social-responsibility-template-js" */),
  "component---src-templates-solutions-template-js": () => import("./../../../src/templates/solutions-template.js" /* webpackChunkName: "component---src-templates-solutions-template-js" */),
  "component---src-templates-work-template-js": () => import("./../../../src/templates/work-template.js" /* webpackChunkName: "component---src-templates-work-template-js" */)
}

